/* eslint-disable max-lines */
import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import leedImage from '../../images/leed.png'
import leedGoldImage from '../../images/leed-gold.png'
import Layout from '../../components/layout'
import Arrow from '../../components/Arrow'
import SEO from '../../components/seo'

import styles from './styles.module.css'
import './styles.css'
import LogoWS from '../../images/ws.png'
import axios from 'axios'

const initialState = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
  rent: '',
  size: '',
  type:'',
  venta: false,
  renta: false,
  lanzamientos: '',
}

function Development(props) {
  let isDesk = true
  const {
    data: {
      development,
      heroes: { edges: heroes },
      distinctions: { edges: distinctions },
      location,
      locationMovil,
      description,
    },
  } = props

  const slider = useRef(null)
  const [state, setState] = useState(initialState)

  function handleSubmit(event) {
    event.preventDefault()
    var sizeMeters = ''
    if (state.size == '-500') {
      sizeMeters = 'Menos de 500 m'
    } else if (state.size == '500-1000') {
      sizeMeters = '500 m2 a 1000 m2'
    } else if (state.size == '+1000') {
      sizeMeters = 'Mas de 1000 m2'
    } else if (state.size == '+2000') {
      sizeMeters = 'Mas de 2000 m2'
    }
    if (development.slug != 'valle') {
      loginApiSalesForce(
        JSON.stringify({
          FirstName: state.name,
          LastName: state.lastname,
          Email: state.email,
          Phone: state.phone,
          mensaje: `${state.message}`,
          m2: state.size,
          Interesado_en__c: 'Renta',
          Desarrollo_P__c: development.form.desarrollo,
          Tipo_de_inmueble__c: development.form.inmueble,
          LeadSource: development.form.lead,
          Medio__c: development.form.medio,
        }),
      )
    } else {
      loginApiSalesForce(
        JSON.stringify({
          FirstName: state.name,
          LastName: state.lastname,
          Email: state.email,
          Phone: state.phone,
          '00NQm000001pZZR': state.type,
          '00NQm000001pZpZ': state.size,
        }),
      )
    }
    
  }

  function loginApiSalesForce(json) {
    // WARNING: For POST requests, body is set to null by browsers.
    var data = JSON.stringify({
      email: 'wsw@correo.com',
      password: 'wswsalesforce1200#',
    })

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function() {
      if (this.readyState === 4) {
        var responseJson = JSON.parse(this.responseText)
        // Accede a la propiedad 'access_token' del objeto JSON
        var accessToken = responseJson.access_token
        // Llama a la función apiSalesForceLead con el token de acceso y el objeto json
        if (development.slug != 'valle') {
          apiSalesForceLead(accessToken, json);
        } else {
          apiSalesForceLeadDelValle(accessToken, json);
        }
        
      }
    })

    xhr.open('POST','https://api.concepthausqa.com/api/auth/login')
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(data)
  }

  function apiSalesForceLead(token, json) {
    // WARNING: For POST requests, body is set to null by browsers.

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function() {
      if (this.readyState === 4) {
        toast.success('Gracias, tus datos han sido enviados')
          window.location.href = `https://oficinasdowntown.mx/thankyou${development.thankyou}`
          setState(initialState)
      }
    })

    xhr.open('POST', 'https://api.concepthausqa.com/api/auth/wswSalesForce')
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)

    xhr.send(json)
  }

  function apiSalesForceLeadDelValle(token, json) {
    // WARNING: For POST requests, body is set to null by browsers.

    var xhr = new XMLHttpRequest()
    xhr.withCredentials = true

    xhr.addEventListener('readystatechange', function() {
      if (this.readyState === 4) {
        toast.success('Gracias, tus datos han sido enviados')
          window.location.href = `https://oficinasdowntown.mx/thankyou${development.thankyou}`
          setState(initialState)
      }
    })

    xhr.open('POST', 'https://api.concepthausqa.com/api/auth/wswSalesForceValle')
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Authorization', 'Bearer ' + token)

    xhr.send(json)
  }

  function handleChange({ target: { name, value } }) {
    console.log(name,value);
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  function handleArrowClick() {
    const HERO_VH_SIZE = 0.9

    window.scrollTo({
      top: window.innerHeight * HERO_VH_SIZE,
      behavior: 'smooth',
    })
  }

  function handleContacto() {
    var elemento = document.getElementById('contacto')
    var posicion = elemento.getBoundingClientRect()
    window.scrollTo({
      top: posicion.top - 80,
      behavior: 'smooth',
    })
  }
  function movildeskImg(ti) {
    var bandera = []
    for (var a = 0; a < heroes.length; a++) {
      bandera.push(heroes[a])
    }
    heroes.splice(0, heroes.length)
    for (var i = 0; i < bandera.length; i++) {
      var imageReformaMovil = bandera[i].node.childImageSharp.fluid.src.split(
        '/',
      )
      let n = imageReformaMovil[4].search(ti)
      if (n == -1) {
        heroes.push(bandera[i])
      }
    }
    // Acomodando imagenes segun su numero
    heroes.sort(function(a, b) {
      let srcA = a['node']['childImageSharp']['fluid']['src'].split('/')
      srcA = srcA[srcA.length - 1]
      let srcB = b['node']['childImageSharp']['fluid']['src'].split('/')
      srcB = srcB[srcB.length - 1]
      let numberImageA = srcA.split('-')
      let numberImageB = srcB.split('-')

      if (development.slug == 'santa-fe') {
        numberImageA = parseInt(numberImageA['2'])
        numberImageB = parseInt(numberImageB['2'])
      } else {
        numberImageA = parseInt(numberImageA['1'])
        numberImageB = parseInt(numberImageB['1'])
      }

      return numberImageA - numberImageB
    })
  }
  if (development.slug == 'reforma' || development.slug == 'valle') {
    state.venta = true
    state.renta = false
  } else if (
    development.slug == 'downtown-club' ||
    development.slug == 'insurgentes' ||
    development.slug == 'santa-fe' ||
    development.slug == 'one-o-one'
  ) {
    state.venta = false
    state.renta = true
  }
  var cert = ''
  var cert2 = ''
  if (development.slug == 'santa-fe') {
    cert = 'PLATINO'
    cert2 = 'platino'
  } else if (development.slug == 'reforma' || development.slug == 'valle') {
    cert = 'ORO'
    cert2 = 'oro'
  } else if (development.slug == 'downtown-club') {
    cert = 'PLATA'
    cert2 = 'plata'
  } 
  // Seccion para acomodar imagenes responsivas
  if (
    development.slug == 'santa-fe' ||
    development.slug == 'downtown-club' ||
    development.slug == 'reforma' ||
    development.slug == 'insurgentes' ||
    development.slug == 'one-o-one' ||
    development.slug == 'valle'
  ) {
    if (typeof screen !== 'undefined') {
      if (screen.width > 640) {
        isDesk = false
        movildeskImg('movil')
      } else {
        isDesk = true
        movildeskImg('desktop')
      }
    }
  }
  console.log(development.slug);

  return (
    <Layout>
      <ToastContainer />
      <SEO title={development.title} />
      <div className={styles.hero}>
       
        
        <Slider ref={slider} arrows={false} autoplay>
          {heroes.map(({ node }) => (
            <Img
              key={node.id}
              className={styles.heroImage}
              fluid={node.childImageSharp.fluid}
            />
          ))}
        </Slider>

        <div className={styles.sliderArrows}>
          <Arrow onClick={() => slider?.current.slickPrev()} left />
          <Arrow onClick={() => slider?.current.slickNext()} right />
        </div>
        <div className={styles.heroBanner}>
          {(development.slug == 'reforma' || development.slug == 'valle') && (
            <div>
              <h1>
                <strong>{development.title}</strong>
                {development.banner}
              </h1>
              <p>{development.lanzamientostitle}</p>
              <p className={styles.lanzamientos}>
                <strong>{development.lanzamientos}</strong>
              </p>
            </div>
          )}
          {(development.slug != 'reforma' && development.slug != 'valle') && (
            <div>
              <h1>
                
                {development.slug != 'downtown-club' &&  development.slug != 'one-o-one' &&
                  development.title.replace(/( Santa Fe| \w*)$/gi, '')}
                {development.slug == 'downtown-club' && (
                  <div className={styles.theHubTitle}>
                    <span className={styles.theHubTitleBold}>DOWNTOWN CLUB REFORMA</span>
                    <span className={styles.theHubTitleLight}>
                      BY BE GRAND
                    </span>
                    <span className={styles.theHubTitleLight2}>
                      Preventa de Oficinas
                    </span>
                    {/* {development.slug != 'downtown-club' && development.title.replace(/^(Downtown) /gi, '')} */}
                  </div>
                )}
                {development.slug != 'downtown-club' && (
                  <strong>
                    {development.slug != 'downtown-club' &&
                      development.title.replace(/^(Downtown) /gi, '')}
                  </strong>
                )}
              </h1>
              <p>{development.banner}</p>
            </div>
          )}

          {development.slug != 'santa-fe' &&
            development.slug != 'insurgentes' && (
              <button
                onClick={handleContacto}
                className={styles.flotante}
                type="button"
              ></button>
            )}

          <Arrow onClick={handleArrowClick} className={styles.arrow} />
        </div>
      </div>
      <div className={styles.description}>
        <div
          className={styles.descriptionStyle}
          dangerouslySetInnerHTML={{
            __html: development.description.body,
          }}
        />

        <div className={styles.descriptionContent}>
          <div className={styles.descriptionImage}>
            <Img fixed={description.childImageSharp.fixed} />
            {development.leed  &&  development.slug == 'santa-fe' &&(
              <div className={styles.leedContainer}>
                <div className={styles.leedHeading}>
                  <img src={leedImage} alt="Logo de LEED" />
                  <p>
                    {development.certifi}
                    <strong>LEED® NIVEL {cert}.</strong>
                  </p>
                </div>
                <p className={styles.leedDescription}>
                  Este proyecto ha conseguido la precertificación LEED® for
                  Building Design and Construction: Core and Shell Development
                  (LEED® BD+C: Core and Shell) nivel (Certificado, {cert2}).
                </p>
              </div>
            )}
            {development.leed  &&  development.slug == 'reforma' &&(
              <div className={styles.leedContainer}>
                <div className={styles.leedHeading}>
                  <img src={leedGoldImage} alt="Logo de LEED" />
                  <p>
                    {development.certifi}
                    <strong>LEED® NIVEL {cert}.</strong>
                  </p>
                </div>
                <p className={styles.leedDescription}>
                Downtown Reforma by Be Grand® ha sido certificado LEED® Nivel Oro.

                </p>
              </div>
            )}
          </div>
          <div className={styles.contentboxColor}>
            {development.slug == 'reforma'  && (
              <div className={styles.boxColordos}>
                <span className={styles.boxColortext}>
                  ¡OFICINAS DESDE 188 M²!
                </span>
              </div>
            )}
            { development.slug == 'valle' && (
              <div className={styles.boxColordos}>
                <span className={styles.boxColortext}>
                  ¡Consultorios médicos diseñados para ti!    
                </span>
              </div>
            )}
            {development.slug == 'downtown-club' && (
              <div className={styles.boxColordos}>
                <span className={styles.boxColortext}>
                  ¡Venta de oficinas de lujo desde 10m²!
                </span>
              </div>
            )}
            {/* {development.slug == 'insurgentes' && (
              <div className={styles.boxColor}>
                <span className={styles.boxColortext}>
                  Ya no hay nada disponible.
                </span>
              </div>
            )} */}

            {development.slug == 'santa-fe' && isDesk == true && (
              <div className={styles.boxColortres}>
                <span className={styles.boxColortext}>
                  ¡ÚLTIMA OFICINA ACONDICIONADA <br></br>DISPONIBLE, 590 M²!
                </span>
              </div>
            )}
            {development.slug == 'one-o-one' && (
              <div className={styles.boxColordos}>
                <span className={styles.boxColortext}>
                  ¡OFICINAS EN RENTA A PARTIR DE 275 M²!
                </span>
              </div>
            )}
            <div className={styles.descriptionBox}>
              <div
                dangerouslySetInnerHTML={{
                  __html: development.description.specs.body,
                }}
              />
              <h4>Datos generales</h4>
              <ul className={styles.specsList}>
                {development.description.specs.items.map((spec, index) => (
                  <li className={styles.specsItem} key={spec.name}>
                    {development.slug != 'downtown-club' && (
                      <i style={{ backgroundPosition: `-${index * 60}px` }} />
                    )}
                    <p>
                      {spec.name == 'Oficinas en' && (
                        <div>
                          <strong>{spec.name}:</strong>
                          Compra <br /> Renta con opción a compra
                        </div>
                      )}
                      {spec.name != 'Oficinas en' && (
                        <div>
                          <strong>{spec.name}</strong> <div dangerouslySetInnerHTML={{
                            __html: spec.value,
                          }}/>
                        </div>
                      )}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.distinction}>
        <div className={styles.distinctionContent}>
          <h2>Nuestra distinción</h2>
          <div className={styles.distinctionItemsWrapper}>
            {development.distinction.map(({ title, body, image }) => (
              <div className={styles.distinctionItem} key={title}>
                <div className={styles.distinctionItemImage}>
                  <div>
                    {distinctions.map(({ node: { name, childImageSharp } }) => {
                      return (
                        name.includes(image) && (
                          <Img key={name} fixed={childImageSharp.fixed} />
                        )
                      )
                    })}
                  </div>
                </div>
                <div className={styles.distinctionItemBody}>
                  <h3>{title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                    __html: body,
                  }}>
                  </p>
                  {title == 'SERVICIOS DOWNTOWN REFORMA' && (
                    <strong>¡Visítanos!</strong>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {development.businesses?.length && (
        <div className={styles.businessesContainer}>
          <h2>Comercios</h2>
          <ul className={styles.businessesList}>
            {development.businesses.map((business) => (
              <li key={business.name} className={styles.businessesItem}>
                <img src={business.image} alt={business.name} />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={styles.location}>
        <div className={styles.locationHero}>
          {isDesk == false && (
            <Img
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                margin: '0 auto',
              }}
              fluid={location.childImageSharp.fluid}
            />
          )}

          {isDesk == true && (
            <Img
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                margin: '0 auto',
              }}
              fluid={locationMovil.childImageSharp.fluid}
            />
          )}

          <h2>Ubicación</h2>
        </div>
        <div className={styles.locationContent}>
          <div className={styles.locationDescription}>
            {development.slug == 'downtown-club' && (
              <strong>Club Downtown</strong>
            )}
            {development.slug != 'downtown-club' && (
              <strong>{development.title}</strong>
            )}
            <p>{development.location.address}</p>
            {development.location.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: development.location.description,
                }}
              />
            )}
            {development.location.showroom && development.slug != 'reforma' && development.slug != 'valle' && (
              <>
                {development.slug == 'downtown-club' && <strong>¡Visítanos!</strong>}
                {development.slug != 'downtown-club' && (
                  <strong>Visita nuestro showroom</strong>
                )}
                <p>{development.location.showroom}</p>
              </>
            )}
             {development.location.showroom && development.slug == 'valle' && (
              <>
                {development.slug == 'downtown-club' && <strong>¡Visítanos!</strong>}
                {development.slug != 'downtown-club' && (
                  <strong>¡Visítanos!</strong>
                )}
                <p>{development.location.showroom}</p>
              </>
            )}
            {development.location.showroom && development.slug == 'reforma' && (
              <>
               
                <p
                  dangerouslySetInnerHTML={{
                    __html: development.location.showroom,
                  }}
                ></p>
              </>
            )}
          </div>
          {development.location.mapUrl && (
            <div className={styles.locationMap}>
              <iframe
                title="Mapa"
                src={development.location.mapUrl}
                width="100%"
                height="325px"
                style={{ border: 'none' }}
              />
            </div>
          )}
        </div>
      </div>
      <div id="contacto" className={styles.contact}>
        <h2>Contacto</h2>
        <div className={styles.contactContent}>
          <form
            id={development.slug}
            name={development.slug}
            onSubmit={handleSubmit}
          >
            <input
              name="name"
              value={state.name}
              onChange={handleChange}
              type="text"
              placeholder="NOMBRE"
              required
            />

            <input
              name="lastname"
              value={state.lastname}
              onChange={handleChange}
              type="text"
              placeholder="APELLIDO"
              required
            />

            <input
              name="email"
              value={state.email}
              onChange={handleChange}
              type="email"
              placeholder="CORREO ELECTRÓNICO"
              required
            />
            <input
              name="phone"
              value={state.phone}
              onChange={handleChange}
              type="tel"
              placeholder="TELÉFONO"
              required
            />
            {development.slug == 'reforma' || development.slug == 'reforma' && (
              <label className="select--label" htmlFor="select">
                Interesado en
                <select
                  name="lanzamientos"
                  value={state.lanzamientos}
                  onBlur={handleChange}
                  onChange={handleChange}
                  id="select"
                  required
                >
                  <option value={null}>Opciones </option>
                  {development.lanzamientosOptions.map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </label>
            )}
            {development.slug == 'downtown-club' && (
              <label className="select--label" htmlFor="select">
                Selecciona el espacio que necesitas
                <select
                  name="lanzamientos"
                  value={state.lanzamientos}
                  onBlur={handleChange}
                  onChange={handleChange}
                  id="select"
                  required
                >
                  <option value={null}>Opciones </option>
                  {development.lanzamientosOptions.map(({ label, value }) => (
                    <option key={label} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </label>
            )}

            <textarea
              name="message"
              value={state.message}
              onChange={handleChange}
              placeholder="MENSAJE"
              
            />

            {development.slug != 'insurgentes' &&
              development.slug != 'reforma' &&
              development.slug != 'santa-fe' &&
              development.slug != 'downtown-club' &&
              development.slug != 'valle' &&
              state.renta && (
                <label className="checkbox--label" htmlFor="rent">
                  Información renta
                  <input
                    name="rent"
                    checked={state.buy === 'RENT'}
                    onChange={() =>
                      handleChange({ target: { name: 'buy', value: 'RENT' } })
                    }
                    id="rent"
                    type="checkbox"
                  />
                </label>
              )}

            {development.slug != 'insurgentes' &&
              development.slug != 'reforma' &&
              !state.renta && (
                <label className="checkbox--label" htmlFor="buy">
                  Información venta
                  <input
                    name="buy"
                    checked={state.buy === 'BUY'}
                    onChange={() =>
                      handleChange({ target: { name: 'buy', value: 'BUY' } })
                    }
                    id="buy"
                    type="checkbox"
                  />
                </label>
              )}

            {development.slug != 'reforma' &&
              development.slug != 'insurgentes' && (
                <label className="checkbox--label" htmlFor="rent"></label>
              )}


            

              {development.slug == 'valle' && (
                <br/>
              )}
              {development.slug == 'valle' && state.type == 'Consultorio'  && (
                <label className="select--label" htmlFor="select">
                  Selecciona la medida de la oficina de tu interés
                  <select
                    name="size"
                    value={state.size}
                    onBlur={handleChange}
                    onChange={handleChange}
                    id="select"
                    required
                  >
                    <option value={null}>Opciones m2</option>
                    {development.reservationOptions.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              
            {development.slug != 'santa-fe' &&
              development.slug != 'insurgentes' &&
              development.slug != 'downtown-club' &&
              development.slug != 'valle' && (
                <label className="select--label" htmlFor="select">
                  Selecciona la medida de la oficina de tu interés
                  <select
                    name="size"
                    value={state.size}
                    onBlur={handleChange}
                    onChange={handleChange}
                    id="select"
                    required
                  >
                    <option value={null}>Opciones m2</option>
                    {development.reservationOptions.map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
              )}

            <input type="submit" value="ENVIAR" />
          </form>

          <div className={styles.contactInfo}>
            {development.location.showroom &&
              development.slug != 'reforma' &&
              development.slug != 'downtown-club' && (
                <>
                  <p>
                    <span>Showroom:</span>
                    {development.location.showroom}
                  </p>
                </>
              )}
              {development.contact.name != null &&
             (
                
                  <p>
                    <span>Nombre:</span>
                    {development.contact.name}
                  </p>
            )}
            <p>
              <span>Mail:</span>
              {development.contact.email}
            </p>
            <p>
              <span>Teléfono:</span>

              {development.slug != 'reforma' && (
                <div>
                  <a
                    href={development.contact.ws}
                    className={styles.whats}
                    target="_blank"
                  >
                    <img src={LogoWS} alt="linkedin" className="logo-concept" />
                  </a>
                  <span className={styles.number}>
                    {development.contact.phone}
                  </span>
                </div>
              )}

              {development.slug == 'reforma' && (
                <span>{development.contact.phone}</span>
              )}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String, $slugRegex: String, $movil: String) {
    development: developmentsYaml(slug: { eq: $slug }) {
      id
      slug
      title
      banner
      leed
      lanzamientos
      lanzamientostitle
      certifi
      description {
        body
        specs {
          body
          items {
            name
            value
          }
        }
      }
      distinction {
        title
        body
        image
      }
      businesses {
        name
        image
      }
      location {
        description
        address
        showroom
        mapUrl
      }
      reservationOptions {
        label
        value
      }
     
    
      lanzamientosOptions {
        label
        value
      }
      thankyou
      form {
        url
        desarrollo
        inmueble
        lead
        medio
        intgeresado
      }
      contact {
        email
        name
        phone
        ws
      }
    }
    heroes: allFile(
      filter: {
        relativeDirectory: { eq: "images/heroes" }
        name: { regex: $slugRegex }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    distinctions: allFile(
      filter: {
        relativeDirectory: { eq: "images/distinctions" }
        name: { regex: $slugRegex }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fixed(width: 400, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
    location: file(
      relativeDirectory: { eq: "images/location" }
      name: { eq: $slug }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    locationMovil: file(
      relativeDirectory: { eq: "images/location" }
      name: { eq: $movil }
    ) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    description: file(
      relativeDirectory: { eq: "images/description" }
      name: { eq: $slug }
    ) {
      childImageSharp {
        fixed(width: 400, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

export default Development
